import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import UserSearch from "../../components/assets/UserSearch";
import Parallax from '../../components/assets/Parallax';
import AddTestimony from '../../components/assets/AddTestimony';
import CompetencesTable from '../../components/assets/CompetencesTable';
import DisplayTemoignages from '../../components/assets/DisplayTemoignages';
import { differenceInDays } from 'date-fns';
import { toast } from 'react-toastify';
import {
    MDBContainer,
    MDBRow,
    MDBCard,
    MDBCardHeader,
    MDBCol,
    MDBCardBody,
    MDBCardFooter,
    MDBIcon,
    MDBTable,
    MDBTableHead,
    MDBTableBody,
    MDBBtn,
    MDBBadge
} from 'mdb-react-ui-kit';
import { http, uploadImage, getUploadedFiles, deleteImage, getProfile } from '../../components/services/httpService';
import { useDarkMode } from '../../components/assets/DarkModeContext';
import { useFetchTemporaryUsers } from '../../components/hooks/useUsersDetails'
import TempUserManagement from './TempUserManagement';
import { sortDate } from '../../utils/dateUtils';

function SuperAdmin() {
    const [users, setUsers] = useState([]);
    const [search, setSearch] = useState('');
    const [acceptedPercentage, setAcceptedPercentage] = useState(0);
    const [renewPercentage, setRenewPercentage] = useState(0);
    const [activeTab, setActiveTab] = useState('gestion Utilisateurs');
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const { isDarkMode } = useDarkMode();
    const [selectedRoles, setSelectedRoles] = useState({});
    const [isUpdating, setIsUpdating] = useState(false);
    const searchTermLower = search.toLowerCase().split(/\s+/);
    const history = useHistory();
    const [selectedFilterRole, setSelectedFilterRole] = useState('');
    const [isRoleSelectVisible, setIsRoleSelectVisible] = useState(false);

    // const filteredUsers = users.filter(user => {
    //     const userFullName = `${user.prenom} ${user.nom}`.toLowerCase();
    //     const matchesName = searchTermLower.every(word => userFullName.startsWith(word));
    //     const matchesRole = selectedFilterRole ? user.role === selectedFilterRole : true;
    //     return matchesName && matchesRole;
    // });

  
    const ConsenmentStatuses = {
        'oui': 'Oui',
        'renouveler': 'Renouveler'
    }

    const filteredUsers = users.filter(user => {
        const userFullName = `${user.prenom} ${user.nom}`.toLowerCase();
        const matchesName = searchTermLower.every(word => userFullName.includes(word));
        const matchesRole = selectedFilterRole ? user.role === selectedFilterRole : true;
        return matchesName && matchesRole;
    });

    const handleTabChange = (newTab) => {
        setActiveTab(newTab);
    };

    const [currentUserID, setCurrentUserID] = useState(null);

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const profile = await getProfile();
                const data = await profile.json();
                setCurrentUserID(data.id);
            } catch (error) {
                console.error("Error fetching profile:", error);
            }
        };

        fetchProfile();
    }, []);


    useEffect(() => {
        http("user/all")
            .then(response => response.json())
            .then(data => {

                const validUsers = data.filter(item => typeof item === 'object' && item.id);
                setUsers(validUsers);
                let totalUsers = data.length;
                let acceptedCount = 0;
                let renewCount = 0;

                data.forEach(user => {
                    if (user.dateCreation) {
                        user.dateCreation = new Date(user.dateCreation).toLocaleDateString();
                    }
                    if (user.dateExpiration) {
                        let expirationDate = new Date(user.dateExpiration);
                        user.dateExpiration = expirationDate.toLocaleDateString();

                        let diffInDays = differenceInDays(expirationDate, new Date());

                        if (diffInDays <= 30) {
                            user.renewStatus = true;
                        } else {
                            user.renewStatus = false;
                        }
                    }
                    const consentValid = user.estAccepte === 'YES' && !user.renewStatus;
                    if (consentValid) {
                        acceptedCount++;
                    }
                    if (user.estAccepte !== 'YES' || user.renewStatus) {
                        renewCount++;
                    }
                   
                    user.consentmentStatus = consentValid 
                    ? ConsenmentStatuses.oui
                    : ConsenmentStatuses.renouveler;
                });

                setUsers(data);
                setAcceptedPercentage((acceptedCount / totalUsers) * 100);
                setRenewPercentage((renewCount / totalUsers) * 100);
            })
            .catch(error => console.error(error));
    }, []);

    const { tempUsers, loading: tempUsersLoading, error: tempUsersError, deleteTempUser } = useFetchTemporaryUsers();


    const [selectedImage, setSelectedImage] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);

    const handleImageChange = (event) => {
        const file = event.target.files ? event.target.files[0] : null;
        setSelectedImage(file);
        setPreviewImage(URL.createObjectURL(file));
    };

    const handleImageUpload = async () => {
        if (selectedImage) {
            return uploadImage(selectedImage)
                .then(response => {
                    toast.success("Image uploaded successfully");
                })
                .catch(error => {
                    console.error('An error occurred while uploading the image', error);
                });
        }
    };

    const [files, setFiles] = useState([]);

    useEffect(() => {
        getUploadedFiles()
            .then(response => response.json())
            .then(data => {
                setFiles(data);
            })
            .catch(error => console.error("Erreur lors de la récupération des fichiers :", error));
    }, []);

    const handleDelete = (filename) => {
        if (window.confirm('Êtes-vous sûr de vouloir supprimer cette image ?')) {
            deleteImage(filename)
                .then(() => {
                    setFiles(files.filter(file => file !== filename));
                })
                .catch((error) => {
                    console.error('Failed to delete image', error);
                });
        }
    };

    const handleChangeRole = (userId, newRole) => {
        setIsUpdating(true);
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ role: newRole })
        };

        http(`user/change-role/${userId}`, requestOptions)
            .then(response => {
                toast.success("Le changement de rôle a été effectué avec succès!");
                setUsers(prevUsers => {
                    return prevUsers.map(user => {
                        if (user.id === userId) {
                            return { ...user, role: newRole };
                        } else {
                            return user;
                        }
                    });
                });

                setIsUpdating(false);
            })
            .catch(error => {
                console.error('An error occurred while changing the role', error);
                setIsUpdating(false);
            });
    };

    const handleDeleteUser = (userId) => {
        if (window.confirm("Êtes-vous sûr de vouloir supprimer cet utilisateur?")) {
            const requestOptions = {
                method: 'DELETE'
            };

            http(`user/delete/${userId}`, requestOptions)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Failed to delete the user');
                    }
                    setUsers(prevUsers => prevUsers.filter(user => user.id !== userId));
                    toast.success('User deleted successfully!');
                })
                .catch(error => {
                    console.error('An error occurred while deleting the user', error);
                });
        }
    };

    const dropdownStyle = {
        position: 'absolute',
        zIndex: 1,
        bottom: '15%',
        left: '80px'
    };

    // Add new state for sorting
    const [sortConfig, setSortConfig] = useState({
        key: null,
        direction: 'ascending'
    });

    // Sorting function
    const sortData = (data, sortConfig) => {
        if (!sortConfig.key) return data;

        return [...data].sort((a, b) => {
            let aValue = a[sortConfig.key];
            let bValue = b[sortConfig.key];

            // Special handling for full name
            if (sortConfig.key === 'fullName') {
                aValue = `${a.prenom || ''} ${a.nom || ''}`.toLowerCase();
                bValue = `${b.prenom || ''} ${b.nom || ''}`.toLowerCase();
            }

            // Special handling for consent status
            if (sortConfig.key === 'consentmentStatus') {
                aValue = a.consentmentStatus.toLowerCase();
                bValue = b.consentmentStatus.toLowerCase();
            }

           // Special handling for date fields
            if (sortConfig.key === 'dateCreation' || sortConfig.key === 'dateExpiration') {

                ({ aValue, bValue } = sortDate(aValue, a, sortConfig, bValue, b)); 
            }

            if (aValue < bValue) {
                return sortConfig.direction === 'ascending' ? -1 : 1;
            }
            if (aValue > bValue) {
                return sortConfig.direction === 'ascending' ? 1 : -1;
            }
            return 0;
        });
    };

    // Handle sort click
    const handleSort = (key) => {
        setSortConfig((prevConfig) => {
            if (prevConfig.key === key) {
                return {
                    key,
                    direction: prevConfig.direction === 'ascending' ? 'descending' : 'ascending'
                };
            }
            return {
                key,
                direction: 'ascending'
            };
        });
    };

    // Get sort icon
    const getSortIcon = (columnKey) => {
        if (sortConfig.key !== columnKey) {
            return <MDBIcon fas icon="sort" className="ms-1" />;
        }
        return sortConfig.direction === 'ascending' 
            ? <MDBIcon fas icon="sort-up" className="ms-1" />
            : <MDBIcon fas icon="sort-down" className="ms-1" />;
    };

    // Apply sorting to filtered users
    const sortedUsers = sortData(filteredUsers, sortConfig);

    const [selectedConsentFilter, setSelectedConsentFilter] = useState('');
    const [isConsentFilterVisible, setIsConsentFilterVisible] = useState(false);

    // Get unique consent statuses from users
    const consentStatuses = Array.from(new Set(users.map(user => user.consentmentStatus)));

    const filteredUsersWithConsent = sortedUsers.filter(user => {
        if (!selectedConsentFilter) return true; // No filter applied
        return user.consentmentStatus === selectedConsentFilter;
    });

    // Modified table head section 
    const TableHeader = () => (
        <MDBTableHead>
            <tr>
                <th onClick={() => handleSort('prenom')} style={{ cursor: 'pointer' }}>Prénom {getSortIcon('prenom')} </th>
                <th onClick={() => handleSort('nom')} style={{ cursor: 'pointer' }}>Nom {getSortIcon('nom')} </th>
                <th style={{ position: 'relative' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span onClick={() => handleSort('role')} style={{ cursor: 'pointer' }}>
                            Rôle {getSortIcon('role')}
                        </span>
                        <MDBIcon 
                            fas 
                            icon="filter" 
                            onClick={() => setIsRoleSelectVisible(!isRoleSelectVisible)} 
                            title="Filtrer"
                            className="ms-2"
                        />
                        {isRoleSelectVisible && (
                            <select
                                style={dropdownStyle}
                                className='py-2'
                                value={selectedFilterRole}
                                onChange={(e) => {
                                    setSelectedFilterRole(e.target.value);
                                    setIsRoleSelectVisible(false);
                                }}
                                onBlur={() => setIsRoleSelectVisible(false)}
                            >
                                <option className='text-white' value="">Tous les rôles</option>
                                <option className='text-white' value="CANDIDAT">Candidat</option>
                                <option className='text-white' value="CONSULTANT">Consultant</option>
                                <option className='text-white' value="MANAGER">Manager</option>
                                <option className='text-white' value="SUPERADMIN">Super Admin</option>
                                <option className='text-white' value="CLIENT">Client</option>
                            </select>
                        )}
                    </div>
                </th>
                <th onClick={() => handleSort('email')} style={{ cursor: 'pointer' }}>
                    Email {getSortIcon('email')}
                </th>
                <th onClick={() => handleSort('dateCreation')} style={{ cursor: 'pointer' }}>
                    Date de création {getSortIcon('dateCreation')}
                </th>
                <th onClick={() => handleSort('dateExpiration')} style={{ cursor: 'pointer' }}>
                    Date de fin {getSortIcon('dateExpiration')}
                </th>
                <th style={{ position: 'relative'}}  >
                    <span onClick={() => handleSort('consentmentStatus')} style={{ cursor: 'pointer' }}>
                        Consentement valide {getSortIcon('consentmentStatus')}
                    </span>
                    <MDBIcon 
                        fas 
                        icon="filter" 
                        title="Filtrer"
                        className="ms-2"
                        onClick={() => setIsConsentFilterVisible(!isConsentFilterVisible)}
                    />
                    {isConsentFilterVisible && (
                        <select
                            style={dropdownStyle}
                            className='py-2'
                            value={selectedConsentFilter}
                            onChange={(e) => {
                                const value = e.target.value;
                                setSelectedConsentFilter(value);
                                // Close the dropdown if a specific option is selected
                                    setIsConsentFilterVisible(false);
                            }}
                            onBlur={(e) => e.preventDefault()}
                        >
                            <option className='text-white' value="">Tous les consentements</option>
                            {consentStatuses.map((status, index) => (
                                <option key={index} className='text-white' value={status}>
                                    {status}
                                </option>
                            ))}
                        </select>
                    )}
                </th>
                <th>Actions</th>
            </tr>
        </MDBTableHead>
    );

    const renderConsentBadge = (user) => {
        return user.consentmentStatus === ConsenmentStatuses.oui
        ? <MDBBadge color='success'>{ConsenmentStatuses.oui}</MDBBadge>
        : <MDBBadge color='warning'>{ConsenmentStatuses.renouveler}</MDBBadge>;
    };

    return (
        <MDBContainer fluid className={isDarkMode ? 'bg-dark-mode' : 'bg-light'}>
            <MDBContainer className="animation-fade-in">
                <div
                    className="p-5 bg-image"
                    style={{
                        height: "300px",
                        backgroundImage: 'url("./bg/bg-super-admin.jpg")',
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        backgroundAttachment: "fixed",
                    }}
                ></div>
            </MDBContainer>
            <div className="text-center justify-content-center align-items-center my-3">
                <h1 className={`text-center p-2 ${isDarkMode ? 'text-white' : ''}`}>Espace Manager</h1>
            </div>
            <div>
                <ul className="nav nav-tabs mb-2 justify-content-center" id="issuesTabs" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button
                            className={`nav-link ${activeTab === 'gestion Utilisateurs' ? 'active' : ''} ${isDarkMode ? 'text-white bg-dark-mode' : ''}`}
                            id="tab-1"
                            onClick={() => handleTabChange('gestion Utilisateurs')}
                        >
                            Gestion des utilisateurs
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button
                            className={`nav-link ${activeTab === 'Gestion logos' ? 'active' : ''} ${isDarkMode ? 'text-white bg-dark-mode' : ''}`}
                            id="tab-2"
                            onClick={() => handleTabChange('Gestion logos')}
                        >
                            Gestion des logos

                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button
                            className={`nav-link ${activeTab === 'Gestion temoignages' ? 'active' : ''} ${isDarkMode ? 'text-white bg-dark-mode' : ''}`}
                            id="tab-3"
                            onClick={() => handleTabChange('Gestion temoignages')}
                        >
                            Gestion des témoignages

                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button
                            className={`nav-link ${activeTab === 'Gestion compétences' ? 'active' : ''} ${isDarkMode ? 'text-white bg-dark-mode' : ''}`}
                            id="tab-3"
                            onClick={() => handleTabChange('Gestion compétences')}
                        >
                            Gestion des compétences
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button
                            className={`nav-link ${activeTab === 'gestion Utilisateurs temporaires' ? 'active' : ''} ${isDarkMode ? 'text-white bg-dark-mode' : ''}`}
                            id="tab-5"
                            onClick={() => handleTabChange('gestion Utilisateurs temporaires')}
                        >
                            Gestion des utilisateurs temporaires
                        </button>
                    </li>
                </ul>
            </div>
            {activeTab === 'Gestion logos' && (
                <MDBContainer>
                    <div className='text-center'>
                        <div className=''>
                            <input type="file" accept="image/*" id="file" onChange={handleImageChange} style={{ display: 'none' }} />
                            <label htmlFor="file" className="BtnCard">
                                Ajouter un logo d'un client
                            </label>
                        </div>
                        <div>
                            {previewImage && <img src={previewImage} alt="Image preview" style={{ width: "100px" }} />}
                        </div>
                        <div className='my-2'>
                            <MDBBtn className="btn-secondary" onClick={handleImageUpload}>Confirmer</MDBBtn>
                        </div>
                    </div>
                    <MDBRow className='row-cols-1 row-cols-md-3 g-4 mt-3 pb-5'>
                        {files.map((file, idx) => (
                            <MDBCol key={idx}>
                                <MDBCard className='h-100 align-items-center py-5'>
                                    <img
                                        className="w-50 img-fluid"
                                        src={`${baseUrl}clients/upload/${file}`}
                                        alt={file}
                                    />
                                    <MDBBtn className="btn-danger m-2" onClick={() => handleDelete(file)}>x</MDBBtn>
                                </MDBCard>
                            </MDBCol>
                        ))}
                    </MDBRow>
                </MDBContainer>
            )}
            {activeTab === 'gestion Utilisateurs' && (
                <MDBRow className='justify-content-center pb-5'>
                    <div className="w-50 pb-5">
                        <UserSearch onSearch={setSearch} disableEnter={true} placeholder="Chercher par nom" />
                    </div>
                    <MDBCol md='10' className='mt-2'>
                        <section>
                            <MDBCard>
                                <MDBCardHeader className='py-3'>
                                    <MDBRow>
                                        <MDBCol size='6'>
                                            <p className='text-uppercase small my-2'>
                                                <strong>Gestion des données</strong>
                                            </p>
                                            <h5 className='mb-0'>
                                                <strong>Valide</strong>
                                                <small className='text-success ms-2'>
                                                    <MDBIcon fas size='sm' icon='arrow-up' className='pe-1' />
                                                    {acceptedPercentage.toFixed(2)} %
                                                </small>
                                                <strong className='ms-2'>Renouvellement</strong>
                                                <small className='text-warning ms-2'>
                                                    <MDBIcon fas size='sm' icon='arrows-alt-v' className='pe-1' />
                                                    {renewPercentage.toFixed(2)} %
                                                </small>
                                            </h5>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBCardHeader>

                                <MDBCardBody>
                                    <MDBRow>
                                        <MDBCol md='12' className='mb-4' style={{ overflowX: 'auto' }}>
                                            <MDBTable hover>
            <TableHeader />
            <MDBTableBody>
                {filteredUsersWithConsent.map(user => (
                                                        <tr key={user.id}>
                                                            <td>{user.prenom}</td>
                                                            <td>{user.nom}</td>
                                                            <td>
                                                                <div style={{ display: 'flex', alignItems: 'center' }}> {/* Contenedor con Flexbox */}
                                                                    <select
                                                                        className='py-2'
                                                                        value={selectedRoles[user.id] || user.role}
                                                                        onChange={(e) => setSelectedRoles(prevRoles => ({
                                                                            ...prevRoles,
                                                                            [user.id]: e.target.value,
                                                                        }))}
                                                                    >
                                                                        <option className='text-white' value="CANDIDAT">Candidat</option>
                                                                        <option className='text-white' value="CONSULTANT">Consultant</option>
                                                                        <option className='text-white' value="MANAGER">Manager</option>
                                                                        <option className='text-white' value="SUPERADMIN">Super Admin</option>
                                                                        <option className='text-white' value="CLIENT">Client</option>
                                                                    </select>
                                                                    <MDBIcon fas icon="check-square" title="Confirmer la sélection" size='lg' className='text-success ms-1' onClick={() => handleChangeRole(user.id, selectedRoles[user.id] || user.role)} disabled={isUpdating} />
                                                                </div>
                                                            </td>
                                                            <td>{user.email}</td>
                                                            <td>{user.dateCreation}</td>
                                                            <td>{user.dateExpiration}</td>
                                                            <td>
                                                                {renderConsentBadge(user)}
                                                            </td>

                                                            <td><MDBIcon fas icon="trash-alt" className="pt-2 text-info" onClick={() => handleDeleteUser(user.id)} /></td>
                                                        </tr>
                                                    ))}
                                                </MDBTableBody>
                                            </MDBTable>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBCardBody>
                            </MDBCard>
                        </section>
                    </MDBCol>
                </MDBRow>
            )}
            {activeTab === 'Gestion temoignages' && (
                <MDBContainer className='pb-5'>
                    <div className={`text-center p-2 ${isDarkMode ? 'text-white' : ''}`}>
                        Ajouter un témoignage
                    </div>
                    <AddTestimony />
                    <div className={`text-center p-2 ${isDarkMode ? 'text-white' : ''}`}>
                        Liste des témoignages
                    </div>
                    <DisplayTemoignages />
                </MDBContainer>
            )}
            {activeTab === 'Gestion compétences' && (
                <MDBContainer className='pb-5'>
                    <CompetencesTable />
                </MDBContainer>
            )}
             {activeTab === 'gestion Utilisateurs temporaires' && (
             <TempUserManagement tempUsers={tempUsers} deleteTempUser={deleteTempUser}/>
            )}
        </MDBContainer>
    );
}

export default SuperAdmin;
