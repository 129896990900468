import { MDBContainer, MDBRow, MDBCol, MDBBtn } from 'mdb-react-ui-kit';
import React, { useState, useEffect } from 'react';
import { http } from '../../components/services/httpService';
import { useHistory, useParams } from 'react-router-dom';

const ValidationForm = ({ user }) => {
  const [confirmation, setConfirmation] = useState('');
  const [error, setError] = useState(null);
  const { token, dataDuration } = useParams();
  const history = useHistory();
  const [formattedDeletionDate, setFormattedDeletionDate] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    console.log("Token:", token, "Data Duration:", dataDuration);
    console.log("User data:", user); // Debugging log for user data
    if (user && user.dateCreation && dataDuration) {
      const duration = Number(dataDuration);
      const creationDate = new Date(user.dateCreation);
      const deletionDate = new Date(creationDate);
      deletionDate.setMonth(creationDate.getMonth() + duration);
      setFormattedDeletionDate(deletionDate.toLocaleDateString('fr-FR', {
        year: 'numeric', month: 'long', day: 'numeric'
      }));

      const generatedMessage = `Lors de la création de votre compte ou du dernier renouvellement de consentement, vous avez autorisé Alcyone Consulting à conserver vos données. 
      Si vous souhaitez prolonger cette autorisation, merci de cliquer sur « j’accepte ». Dans le cas contraire, nous procéderons à la 
      suppression de vos données en date du ${deletionDate.toLocaleDateString('fr-FR', {
        year: 'numeric', month: 'long', day: 'numeric'
      })}.`;
      
      console.log("Generated message:", generatedMessage); // Debugging log for generated message
      setMessage(generatedMessage);

    } else {
      console.error("Missing user data or dataDuration:", user, dataDuration);
      setMessage("Les informations de création de compte sont manquantes ou incorrectes.");
    }
  }, [user, dataDuration]);

  const handleSubmit = () => {
    setError(null);

    if (!token || !confirmation || !dataDuration) {
      setError('Données manquantes pour la soumission.');
      return;
    }

    const requestData = {
      token,
      confirmation,
      dataDuration: parseInt(dataDuration, 10),
    };

    console.log("Request data being sent:", requestData); // Debugging log for request data

    http('api/v1/auth/validate', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => {
        if (response.ok) {
          history.push('/');
        } else {
          throw new Error('Erreur lors de la confirmation des données');
        }
      })
      .catch((error) => {
        console.error('Erreur lors de la confirmation des données', error);
        setError('Une erreur s\'est produite lors de la confirmation de vos données. Veuillez réessayer.');
      });
  };

  const handleAccept = () => {
    setConfirmation('oui');
    handleSubmit();
  };

  const handleRefuse = () => {
    setConfirmation('non');
    handleSubmit();
  };

  return (
    <MDBContainer fluid className="fond-accueil">
      <MDBRow className='d-flex justify-content-center align-items-center text-center text-white' style={{ minHeight: "80VH" }}>
        <MDBCol md="6" className="form-card">
          <h1>Bonjour {user?.prenom} {user?.nom},</h1>
          <form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
            <p className='mt-3 h5'>{message}</p>
            {error && <p className='text-danger'>{error}</p>}
            <div className='d-flex justify-content-center gap-3'>
              <MDBBtn onClick={handleAccept} className='BtnCard'>J'accepte</MDBBtn>
              <MDBBtn onClick={handleRefuse} className='BtnCardSecondary'>Je refuse</MDBBtn>
            </div>
          </form>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default ValidationForm;
