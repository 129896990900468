import React, { Component } from 'react';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Vous pouvez également enregistrer l'erreur dans un service de rapport d'erreurs, par exemple :
    // logErrorToMyService(error, errorInfo);
    console.error('Error:', error, 'Info:', errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // Vous pouvez afficher un message d'erreur personnalisé ou un autre contenu de secours.
      return <h1>Quelque chose a mal tourné.</h1>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
