import React, { useState } from 'react';
import { MDBRow, MDBCol, MDBTable, MDBTableHead, MDBTableBody, MDBIcon } from 'mdb-react-ui-kit';
import './TempUserManagement.css';
import { sortDate } from '../../utils/dateUtils'; // Import the sortDate function

const TempUserManagement = ({ tempUsers, deleteTempUser }) => {
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
    const sortedTempUsers = React.useMemo(() => {
        if (!sortConfig.key) return tempUsers;
        return [...tempUsers].sort((a, b) => {
            let aValue = a[sortConfig.key];
            let bValue = b[sortConfig.key];

            // Handle date sorting for specific columns
            if (['dateExpiration', 'activationLinkSentAt'].includes(sortConfig.key)) {
                ({ aValue, bValue } = sortDate(aValue, a, sortConfig, bValue, b));
            }

            if (aValue < bValue) {
                return sortConfig.direction === 'ascending' ? -1 : 1;
            }
            if (aValue > bValue) {
                return sortConfig.direction === 'ascending' ? 1 : -1;
            }
            return 0;
        });
    }, [tempUsers, sortConfig])

    const requestSort = (key) => {
        
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const getIcon = (key) => {
        if (sortConfig.key !== key) {
            return <MDBIcon fas icon="sort" className="ms-1" />;
        }
        return sortConfig.direction === 'ascending' 
            ? <MDBIcon fas icon="sort-up" className="ms-1" />
            : <MDBIcon fas icon="sort-down" className="ms-1" />;
    };

    const handleDeleteTempUser = (userId) => {
        if (window.confirm("Êtes-vous sûr de vouloir supprimer cet utilisateur?")) {
            deleteTempUser(userId)
        }
    };

    return (
        <MDBRow className='justify-content-center pb-5 bg-white'>
            <MDBCol md='10' className={`mt-2 text-center p-2`}>
            <section>
            <MDBTable>
                <MDBTableHead>
                    <tr>
                        <th className="sortable-column" onClick={() => requestSort('prenom')}>Prénom {getIcon('prenom')} </th>
                        <th className="sortable-column" onClick={() => requestSort('nom')}>Nom {getIcon('nom')} </th>
                        <th className="sortable-column" onClick={() => requestSort('role')}>Rôle {getIcon('role')} </th>
                        <th className="sortable-column" onClick={() => requestSort('email')}>Email {getIcon('email')} </th>
                        <th className="sortable-column" onClick={() => requestSort('dateExpiration')}>Date d'Expiration {getIcon('dateExpiration')} </th>
                        <th className="sortable-column" onClick={() => requestSort('managerName')}>Manager {getIcon('managerName')} </th>
                        <th className="sortable-column" onClick={() => requestSort('activationLinkSentAt')}>Date demande {getIcon('activationLinkSentAt')} </th>
                        <th>Actions</th>
                    </tr>
                </MDBTableHead>
                <MDBTableBody>
                    {sortedTempUsers.map((user) => (
                        <tr key={user.id}>
                            <td>{user.prenom}</td>
                            <td>{user.nom}</td>
                            <td>{user.role}</td>
                            <td>{user.email}</td>
                            <td>{user.dateExpiration}</td>
                            <td>{user.managerName}</td>
                            <td>{user.activationLinkSentAt}</td>
                            <td><MDBIcon fas icon="trash-alt" className="pt-2 text-info" onClick={() => handleDeleteTempUser(user.id)} /></td>
                        </tr>
                    ))}
                </MDBTableBody>
            </MDBTable>
            </section>
            </MDBCol>
        </MDBRow>
    );
};

export default TempUserManagement;