export function sortDate(aValue, a, sortConfig, bValue, b) {
    try {
        const parseDate = (dateStr) => {
            if (!dateStr) return null; // Earliest possible null value if the date is null

            const [day, month, year] = dateStr.split('/');
            return new Date(year, month - 1, day); // month is 0-based in JS Date
        };
        // Use the correct field based on sortConfig.key
        aValue = parseDate(a[sortConfig.key]);
        bValue = parseDate(b[sortConfig.key]);
    } catch (e) {
        console.log('Something went wrong while parsing the date in Sort function: ', e);
    }
    return { aValue, bValue };
}
